.swiper-slide {
  width: 43%;

  @screen sm {
    width: 30%;
  }

  @screen md {
    width: 22%;
  }

  @screen lg {
    width: 17.8%;
  }

  @screen xl {
    width: 15.79%;
  }
}