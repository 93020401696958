.bambooContainer :global(#BambooHR-ATS) h2 {
  display: none;
}

.bambooContainer :global(#BambooHR-ATS) li {
  color: black;
  font-family: "Helvetica Neue LT Std 53 Extended", Helvetica Neue, sans-serif;
  padding-bottom: 5px;
}

.bambooContainer :global(#BambooHR-ATS) .BambooHR-ATS-Department-Header {
  font-size: 14px;
}

.bambooContainer :global(#BambooHR-ATS) .BambooHR-ATS-Jobs-Item a {
  font-weight: normal;
  margin-right: 15px;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.bambooContainer :global(#BambooHR-ATS) .BambooHR-ATS-Jobs-Item span {
  font-size: 10px;

  @media screen and (min-width: 768px) {
    font-size: 12px;
  }
}
