.Select {
  color: black;
}

.Select-control {
  border: 1px solid black;
  border-radius: 0;
  height: auto;

  &:hover {
    box-shadow: none;
  }
}

.Select-grey .Select-control {
  border-color: theme('colors.darker-grey');
}

.Select-pastel-grey .Select-control {
  background: theme('colors.pastel-grey');
}

.Select-input {
  line-height: theme('lineHeight.tight');
  padding: theme('padding.1');
  height: auto;

  @media screen and (min-width: theme('screens.sm')) {
    padding: theme('padding.2');
  }

  &:before {
    content: "_";
    visibility: hidden;
  }
}

.Select--single > .Select-control .Select-value,
.Select-option,
.Select-placeholder  {
  line-height: theme('lineHeight.tight');
  padding: theme('padding.1');

  @media screen and (min-width: theme('screens.sm')) {
    padding: theme('padding.2');
  }
}

.Select-placeholder {
  text-transform: uppercase;
  color: theme('colors.darkest-dark-grey');
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 3px rgba(0, 0, 0, .1);
  background: #fff;
}

.Select.is-focused:not(.is-open) > .Select-control .Select-value {
  background: #ececec;
}

.Select-pastel-grey.is-focused:not(.is-open) > .Select-control {
  border-color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 3px rgba(0, 0, 0, .1);
  background: theme('colors.pastel-grey');
}

.Select.is-open > .Select-control {
  border-color: black;
}

.Select-pastel-grey.is-open > .Select-control {
  background: theme('colors.pastel-grey');
}

.Select-pastel-grey > .Select-control .Select-input {
  background: theme('colors.pastel-grey');
}

.Select-arrow {
  border-color: black transparent transparent;
}

.Select.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent black;
}

.Select-menu-outer {
  border-color: black;
  border-radius: 0;
}

/* Select has label next to it */
.selectHasLabel .Select-control {
  border: none;
}

.selectHasLabel .Select.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
  box-shadow: none;
}

.selectHasLabel .Select-menu-outer {
  margin-top: 0;
  left: 2px;
}

.SelectIsSmall {
  & .Select--single > .Select-control .Select-value,
  & .Select-option,
  & .Select-placeholder,
  & .Select-input {
    padding: theme('padding.1');
  }
}

.SelectIsInline {
  & .Select--single > .Select-control .Select-value,
  & .Select-option,
  & .Select-placeholder,
  & .Select-input {
    padding: theme('padding.05') theme('padding.1');
  }
}

.SelectIsInverted {
  & .Select-menu-outer {
    box-shadow: none;
  }
  & .Select-option {
    color: theme('colors.black');
    border-radius: 0;
    transition-property: color, background-color;
    transition-duration: var(--transitionLength);
  }
  & .Select-option.is-focused {
    color: theme('colors.white');
    background-color: theme('colors.black');
  }
  & .Select-option:not(.is-focused) {
    background-color: theme('colors.white');
  }
  & .Select-value .Select-value-label {
    /* Override the default react-select theme colour */
    color: theme('colors.black') !important;
  }
}
