.pca .pcaautocomplete {
  margin: -2px 0 !important;
  border-color: theme('colors.black') !important;
  border-radius: 0 0 2px 2px !important;
  border-bottom: solid !important;
  font-family: theme('fontFamily.sans') !important;
}

.pca .pcalist {
  background-color: theme('colors.white') !important;
  border-bottom-width: 0px !important;
}

.pca .pcaitem, .pca .pcaselected {
  padding: 15px 10px !important;
  border-bottom-color: theme('colors.grey.light') !important;
}
