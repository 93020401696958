.rc-tooltip {
  opacity: 1;
  transition: opacity 0.1s;
}

.rc-tooltip-inner {
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid black;
  color: black;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  opacity: 0;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  opacity: 0;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  opacity: 0;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  opacity: 0;
}

@keyframes rcToolTipFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcToolTipFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rc-tooltip.fade-enter, .rc-tooltip.fade-leave {
  display: block;
}

.rc-tooltip.fade-enter, .rc-tooltip.fade-appear {
  opacity: 0;
}

.rc-tooltip.fade-enter-active, .rc-tooltip.fade-appear.fade-appear-active {
  opacity: 1;
}

.rc-tooltip.fade-leave.fade-leave-active {
  opacity: 0;
}
