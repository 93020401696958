@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=8558e8c0-6caa-4eea-9829-6f0df2c4e1b3&fontids=5345266");
@font-face{
    font-family:"Helvetica Neue LT Std 53 Extended";
    src:url("./fonts/708b7649-0533-451d-99be-c6d66bf6a6d7.eot?#iefix");
    src:url("./fonts/708b7649-0533-451d-99be-c6d66bf6a6d7.eot?#iefix") format("eot"),url("./fonts/fdf81c22-8f80-4f1a-901c-7edd6ff65831.woff2") format("woff2"),url("./fonts/fc832a5f-8d9c-414d-86a5-24d1932e184d.woff") format("woff"),url("./fonts/1db703cb-412b-4f13-9991-c4ad09f109b3.ttf") format("truetype");
}

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindlabs/tailwindcss/blob/master/src/plugins/css/preflight.css
 */
@tailwind base;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */
.button {
  @apply .cursor-pointer .select-none .inline-flex .appearance-none .border-solid .border-1 .whitespace-no-wrap .leading-tight;
  transition: background-color var(--transitionLength);
}

.button:focus {
  @apply outline-focus;
}

.heading {
  @apply uppercase font-normal leading-tight text-black;
}

.flex-grid {
  @apply flex
}

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, you should import this line from it's own file:
 *
 * @import "./tailwind-utilities.css";
 *
 * See: https://github.com/tailwindcss/tailwindcss/issues/53#issuecomment-341413622
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

@responsive {
  .content-stretch {
    align-content: stretch;
  }
  .justify-stretch {
    justify-content: stretch;
  }
}

html {
  @apply .font-sans .leading-normal;
  font-size: 62.5%; /* 10px so 1rem = 10px */
}

/* <body> should have a background defined */
body {
  @apply .text-base;
  background-color: white;

  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Apply font on <text> svg elements by default */
text {
  @apply .font-sans;
}

/* Fix a bug where buttons couldn't override webkit-appearance */
html [type="button"] {
  @apply .appearance-none;
}

.hyphens {
  hyphens: auto;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-overflow-anchor {
  overflow-anchor: none;
}

.outline-default {
  outline: theme('colors.darker-grey') solid 1px;
}

.outline-focus {
  outline: var(--focusRingColor) solid 3px;
}

.invert {
  filter: invert(1);
}

/* Remove ugly handles on Chrome/Mozilla for number inputs (until mouse hover) */
/* Only on desktop */

@media screen and (min-width: 768px) {
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

/* Placeholder style */
*::-webkit-input-placeholder {
  color: theme('colors.grey.dark') !important;
  opacity: 1 !important;
}
*:-moz-placeholder {
    /* FF 4-18 */
  color: theme('colors.grey.dark');
  opacity: 1 !important;
}
*::-moz-placeholder {
    /* FF 19+ */
  color: theme('colors.grey.dark') !important;
  opacity: 1 !important;
}
*:-ms-input-placeholder {
    /* IE 10+ */
  color: theme('colors.grey.dark') !important;
  opacity: 1 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus,
button:focus {
  outline: none;
}

ul, ol {
  padding: 0;
}

li {
  list-style-type: none;
}

/* Set <Paragraph /> components default globally so we save bytes in the JS */
p {
  @apply .text-black .text-base;
}

/* Display svg as block by default because we almost never inline them */
svg,
picture {
  display: block;
}

picture img {
  width: 100%;
  height: auto;
  display: block;
}


button, optgroup, select {
  @apply .leading-tight;
}

button, input, input[type=text], optgroup, select, textarea {
  border-radius: 0;
  @apply .appearance-none;
}

input:focus,
input[type=text]:focus,
textarea:focus {
  outline: none;
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

/* Dashboard campaign page masonry layout */
.masonry {
  display: flex;
  margin-left: -2rem; /* gutter size offset */
  width: auto;
}

.masonry-col {
  padding-left: 2rem; /* gutter size */
  background-clip: padding-box;
}

.masonry-col > div {
  margin-bottom: 2rem;
}

/* Campaign builder */
.moveable-line {
  display: none !important;
}

/**
* Global variables
**/
:global {
  & :root {
    --transitionLength: 0.1s;
    --focusRingColor: #d6d6d6;
    --focusBgColor: #ececec;
  }
}
