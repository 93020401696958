.browser-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -54px 0 0 -84px;
}

.frame {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  transform-box: fill-box;
  transform-origin: center;
  transform: translate(50px,0);
  width: 50px;
  height: 97px;
  animation: frame-animation 2.5s cubic-bezier(0.68,-0.55,0.27,1.55)  alternate infinite;
}

.arrow-top-left {
  animation: arrow-top-left 2.5s cubic-bezier(0.68,-0.55,0.27,1.55)  alternate infinite;
}

.arrow-bottom-right {
  animation: arrow-bottom-right 2.5s cubic-bezier(0.68,-0.55,0.27,1.55)  alternate infinite;
}

.animate-tick {
  transform: translate(5px,5px);
  animation: tick 2.5s cubic-bezier(0.68,-0.55,0.27,1.55)  alternate infinite;
}

.animate-cross {
  transform: translate(5px,5px);
  animation: cross 2.5s cubic-bezier(0.68,-0.55,0.27,1.55) alternate infinite;
}

@keyframes frame-animation {
  0% {
    transform: translate(53px,7px);
    width: 50px;
    height: 83px;
  }

  25% {
    transform: translate(53px,7px);
    width: 50px;
    height: 83px;
  }

  75% {
    transform: translate(0px,0);
    width: 156px;
    height: 97px;
  }

  100% {
    transform: translate(0px,0);
    width: 156px;
    height: 97px;
  }
}

@keyframes arrow-top-left {
  0% {
    transform: translate(55px,9px);
  }

  25% {
    transform: translate(55px,9px);
  }

  75% {
    transform: translate(5px,5px);
  }

  100% {
    transform: translate(5px,5px);
  }
}

@keyframes arrow-bottom-right {
  0% {
    transform: translate(-45px,1px);
  }

  25% {
    transform: translate(-45px,1px);
  }

  75% {
    transform: translate(5px,5px);
  }

  100% {
    transform: translate(5px,5px);
  }
}

@keyframes tick {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cross {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
