.BrainhubCarousel__dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot {
  outline: 0;
  padding: 10px 5px;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
  background: theme('colors.black');
}
.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  border: 1px solid theme('colors.black');
  background: transparent;
}
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
  outline: 0;
  padding: 10px;
  border: none;
  opacity: 0.5;
  cursor: pointer;
}
.BrainhubCarousel__dots
  .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
  opacity: 1 !important;
}
.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
  opacity: 1;
}
.BrainhubCarousel__thumbnail[type='button'] {
  -webkit-appearance: none;
}
.BrainhubCarousel {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.BrainhubCarousel .BrainhubCarousel__trackContainer {
  overflow: hidden;
}
.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  display: flex;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.BrainhubCarousel
  .BrainhubCarousel__trackContainer
  .BrainhubCarousel__track.BrainhubCarousel__track--transition {
  transition: transform;
}
.BrainhubCarousel__arrows {
  cursor: pointer;
}
.BrainhubCarousel__arrows {
  position: relative;
  padding: 21px;
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
}
.BrainhubCarousel__arrows span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: theme('colors.white');
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0;
}
.BrainhubCarousel__arrows:hover:enabled span {
  border-color: theme('colors.white');
  margin: 0;
}
.BrainhubCarousel__arrows:disabled {
  background-color: theme('colors.darker-grey');
}
.BrainhubCarousel__arrowLeft span {
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: 2.45px;
}
.BrainhubCarousel__arrowRight span {
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: -2.45px;
}
.BrainhubCarouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
  cursor: pointer;
}
.BrainhubCarouselItem .debug-number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 2em;
  text-shadow: 0px 0px 9px theme('colors.white');
}

/* Overrides */

.BrainhubCarousel {
  overflow: visible;
  cursor: grab;
}
.BrainhubCarousel:active {
  cursor: grabbing;
}

.BrainhubCarousel__dots {
  pointer-events: none;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  -webkit-tap-highlight-color: transparent;
}

.BrainhubCarousel__customArrows {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  min-height: 2.8rem;
  z-index: 50;
}

.BrainhubCarousel__custom-arrowLeft {
  width: 0;
  height: fit-content;
}

.BrainhubCarousel__custom-arrowRight {
  width: 0;
  height: fit-content;
}

.BrainhubCarouselItem {
  display: block;
  justify-content: initial;
  align-items: initial;
}
