.rc-pagination {
  & > li {
    list-style: none;
  }
}

.rc-pagination-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.rc-pagination-item,
.rc-pagination-next,
.rc-pagination-jump-next,
.rc-pagination-jump-prev {
  margin-left: 5px;
}

.rc-pagination-next,
.rc-pagination-prev {
  text-transform: uppercase;
}
